<template>
  <div class="test-mode">
    <!-- 注塑模具智能试模 -->
    <div class="content">
      <h1 id="znsm">注塑模具智能试模</h1>

      <div class="second-nav">
        <ul class="second-level-directory" id="second-level-directory">
          <div class="nav-box">
            <li class="nav-link" v-for="nav,index in nav_data" :key="index">
              <a :href="nav.href">{{ nav.name }}</a>
            </li>
          </div>
        </ul>
      </div>

      <!-- <div class="glance">
        <div v-for="item, index in glance_info" :key="index">
          <div class="title-data">
            <h1>{{displayedNumbers[index]}}<span>{{item.unit}}</span></h1>
          </div>
          <h2 style="font-weight: 400; letter-spacing: 3px">{{item.desc}}</h2>
        </div>
      </div> -->
      <div style="height: 30px"></div>
      <div id="introduction" style="margin-top: 50px;">
        <h1 style="text-align: center;">行业痛点</h1>
        <div style="height: 50px"></div>
        <div class="industry-introduction">
          <div class="pain-point-analysis" v-for="item, index in analysis_info" :key="index">
            <h3>{{item.title}}</h3>
            <p>{{item.desc}}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="core-function" id="corefunction" style="height: 950px">
      <h1>核心功能</h1>
      <div class="fun-content">
        <ul class="nav">
          <li 
            v-for="nav, index in nav_info" 
            :key="index" 
            @click="handleNav(index)"
            :class="{ active: index == activeIndex}"
          >
            {{nav.title}}
          </li>
        </ul>
        <div style="height: 40px"></div>
        <div class="img">
          <div v-for="(item, index) in nav_info" :key="index" class="myBox">
            <div class="myImg">
              <img :src="item.img_url" alt="" width="1200px" height="520px" v-if="index != 6 && index != 7 && index != 8">
              <video v-else :src="item.img_url" controls width="1200px" height="520px"></video>
            </div>
            <div class="desc"><span>{{item.desc}}</span></div>
          </div>
        </div>
      </div>
    </div>

    <div class="content" id="advantage">
      <h1 style="text-align: center">亮点优势</h1>
      <div style="height: 70px"></div>
      <div class="advantage">
        <div class="item" v-for="item, index in advantage_info" :key="index">
          <h1>{{item.title}}</h1><br>
          <span>{{item.desc}}</span>
        </div>
      </div>
    </div>

    <div class="earning-value">
      <div style="height: 70px;"></div>
      <h1 style="text-align: center;">收益价值</h1>
      <ul>
        <li>
          <div class="jz-data">
            <span>50%+</span>
            <em>↓</em>
          </div>
          <div class="jz-desc">延期减少</div>
        </li>
        <li>
          <div class="jz-data">
            <span>10%+</span>
            <em>↓</em>
          </div>
          <div class="jz-desc">制造周期缩短</div>
        </li>
        <li>
          <div class="jz-data">
            <span>15%+</span>
            <em>↑</em>
          </div>
          <div class="jz-desc">人均产值提升</div>
        </li>
        <li>
          <div class="jz-data">
            <span>10%+</span>
            <em>↑</em>
          </div>
          <div class="jz-desc">设备利用率提升</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      glance_info: [
        { number: '500', unit: '万元', desc: '近五年研发投入' },
        { number: '500', unit: '小时+', desc: '预计节省时间' },
        { number: '500', unit: '万元+', desc: '预计节省成本' },
      ],
      displayedNumbers: [],
      analysis_info: [
        { title: '试模过程控制难', desc: '传统试模是一个黑匣子过程，试模员根据自己习惯和喜好来操作，试模过程中经常出现爆模、设备损坏等问题，因为缺乏监控手段，当问题出现之后，无法溯源和解决，导致问题重复出现。' },
        { title: '模具质量保证难', desc: '传统试模主要关注的是产品质量，当产品质量出现问题时，主要通过工艺参数的调整来得到相对较好的产品，忽视了对模具本身问题的发现与解决，从而导致量产时返模、修模次数多，量产难。' },
        { title: '工艺知识传承难', desc: '传统试模过程数据主要以纸质或者excel电子表格等方式记录和保存，所保存的知识都是静止、分散和割裂的，对于资深工程师而言，信息查找仍然非常低效；而对于经验不足的员工而言，这些知识则像是被隐藏起来，无法高效重用。' },
        // { title: '制品质量保证难', desc: '注塑成型量产过程中，由于设备、材料、环境等因素的波动，会导致成型过程的波动，从而导致产品质量的波动。在实际生产过程中，针对这种波动，传统方法主要是采取人工不定期的抽查，存在着效率低下、产品质量难以保证的问题。' },
      ],
      nav_info: [
        { title: '标准化流程', desc: '标准化试模流程，对试模员经验要求低，试模过程可溯源', img_url: require('@/assets/images/product/testmode/test-mode1.png') },
        { title: '注塑机通讯', desc: '注塑机数据自动获取，与MES、SAP、OA等系统的数据通讯', img_url: require('@/assets/images/product/testmode/test-mode2.png') },
        { title: '数据存储', desc: '试模数据数据库存储，方便后期检索与重用', img_url: require('@/assets/images/product/testmode/test-mode3.png') },
        { title: '自动生成报告', desc: '试模结果突出显示与自动生成', img_url: require('@/assets/images/product/testmode/test-mode4.png') },
        { title: '一键导入', desc: '模流数据一键导入，方便数据对比与查看', img_url: require('@/assets/images/product/testmode/test-mode5.png') },
        { title: '智能匹配', desc: '模具、工艺与注塑机快速匹配', img_url: require('@/assets/images/product/testmode/test-mode6.png') },
        { title: '智能工艺', desc: '初始工艺参数智能设置', img_url: require('@/assets/images/product/testmode/test-mode7.mp4') },
        { title: '缺陷修正', desc: '成型缺陷智能修正', img_url: require('@/assets/images/product/testmode/test-mode8.mp4') },
        { title: '修正规则', desc: '缺陷修正规则在线编辑与展示', img_url: require('@/assets/images/product/testmode/test-mode9.mp4') },
        { title: '智能移植', desc: '工艺参数在不同机台的智能移植', img_url: require('@/assets/images/product/testmode/test-mode10.png') },
      ],
      activeIndex: 0,
      advantage_info: [
        { title: '部署灵活', desc: '支持线下本地和云端部署，可按需选择模块部署。' },
        { title: '多工厂管理', desc: '满足多工厂一体化管理，可兼容不同分子公司的不同生产模式，实现营业数据相互独立。' },
        { title: '定制服务', desc: '提供二次开发服务，与系统功能实现代码隔离，可为企业提供个性化定制服务。' },
        { title: '模具一体化', desc: '通过一体化平台，实现模具设计、编程、供应链、加工自动化、AGV、IOT、WMS等进行深度融合，信息互联互通，实现模具智能制造。' },
      ],
      nav_data: [
        { name: "行业痛点", href: '#introduction' },
        { name: "核心功能", href: '#corefunction' },
        { name: "亮点优势", href: '#advantage' },
      ]
    }
  },
  mounted() {
    this.startCounting()
    document.addEventListener('scroll', this.handleWheel)
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.handleWheel);
  },
  methods: {
    startCounting() {
      this.displayedNumbers = new Array(this.glance_info.length).fill(0)
      const targetNumbers = this.glance_info.map(item => parseInt(item.number))
      let currentNumbers = new Array(this.glance_info.length).fill(0)

      const timer = setInterval(() => {
        for (let i = 0; i < this.glance_info.length; i++) {
          if (currentNumbers[i] < targetNumbers[i]) {
            currentNumbers[i] += 20
          } else {
            clearInterval(timer)
          }
        }

        this.displayedNumbers = [...currentNumbers]
      },100)
    },
    handleNav(index) {
      this.activeIndex = index;
      var box = document.querySelectorAll(".myBox");
      for (let i = 0; i < box.length; i++) {
        box[i].style.transform = `translateX(${index * -1200}px)`;
        box[i].style.transition = "transform 0.5s ease-in-out";
      }
    },
    handleWheel() {
      var targetUrl = document.getElementById('second-level-directory')
      var znsm = document.getElementById('znsm')
      var introduction = document.getElementById('introduction')
      var distanceToTop = znsm.getBoundingClientRect().top;
      const topOffset = introduction.getBoundingClientRect().top;

      if (distanceToTop < 141) {
        targetUrl.style.position = 'fixed'
        targetUrl.style.top = '90px'
      } else if (distanceToTop == 141) {
        targetUrl.style.top = '200px'
      }

      const updateLinkStyles = function(index) {
        const lis = document.querySelectorAll(".nav-link")
        lis.forEach((li, i) => {
          if (i === index) {
            li.style.borderBottom = '2px solid red';
          } else {
            li.style.borderBottom = 'none';
          }
        })
      }

      if (topOffset <= 304 && topOffset > -251) {
        updateLinkStyles(0);
      } else if (topOffset <= -251 && topOffset > -1208) {
        updateLinkStyles(1);
      } else if (topOffset <= -1208) {
        updateLinkStyles(2);
      }
    },
  }
};
</script>

<style lang="less" scoped>
.industry-introduction {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .pain-point-analysis {
    width: 270px;
    height: 320px;
    padding: 20px;
    box-sizing: border-box;
    border: 1px solid #dddddd;
    box-shadow: 0 4px 12px rgab(0, 0, 0, 0.04);
    h3 {
      text-align: center;
      margin-bottom: 20px;
    }
    h3:hover {
      color: rgb(215, 6, 6);
    }
    p {
      font-size: 15px;
      color: #999999;
      line-height: 26px;
    }
  }
}

.fun-content {
  position: relative;
  .nav {
    margin: 0 auto;
    width: 1100px;
    display: flex;
    justify-content: space-around;
    li {
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
    }
  }
  .img {
    width: 1200px;
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
    margin-left: 50%;
    transform: translate(-50%);
    border-radius: 35px;
    background: white;
    box-sizing: border-box;
    .myImg {
      display: flex;
    }
    .desc {
      width: 80%;
      margin: 70px auto;
      font-size: 20px;
      letter-spacing: 3px;
      color: #828181;
    }
  }
}
.glance {
  display: flex;
  justify-content: space-between;
  .title-data h1 {
    font-size: 68px;
    span {
      font-size: 30px;
      margin-left: 20px;
    }
  }
}
.earning-value {
  position: relative;
  width: 100%;
  height: 390px;
  background: rgb(246, 246, 246);
  background-size: cover;
  ul {
    width: 1200px;
    height: 120px;
    margin: 50px auto;
    display: flex;
    justify-content: space-between;
    background: rgba(255,255,255, 0.6);
    border: 1px solid rgba(255, 255, 255);
    li {
      padding: 20px;
      color: rgb(194, 53, 51);

      span {
        font-size: 40px;
      }
      em {
        font-style: normal;
        font-weight: 800;
        margin-left: 10px;
        font-size: 42px;
        color: rgb(194, 53, 51);
      }
    }
  }
}
#introduction, #corefunction, #advantage {
  scroll-margin-top: 200px;
}
</style>